import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";

import DateLaZi from "./DateLaZi";
import GreetingCard from "./GreetingCard";
import WidgetsWrapper from "./WidgetsWraper";
import RecentOrders from "./RecentOrders";
import UltimeleInregistrari from "./UltimeleInregistrari";
import FluxDeseuri from "./FluxDeseuri";
import PerCapita from "./PerCapita";
import RecentePrimarii from "./RecentePrimarii";


// Dezactivarea service worker-ului
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}
const Dashboard = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Depozitare" parent="Dashboard" title="Depozitare" />
      <Container fluid={true}>
        <Row className="widget-grid">
          <GreetingCard />
          <WidgetsWrapper />
          <DateLaZi />
          
          <FluxDeseuri/>
          <PerCapita/>
          <UltimeleInregistrari />
          <RecentePrimarii/>
          
         
        </Row>
      </Container>
    </Fragment>
  );
};

export default Dashboard;
