import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardHeader, CardBody, Nav, NavItem, NavLink, Input } from 'reactstrap';
import { API_URL } from '../../../config';
import { H4, Btn,H6 } from '../../../AbstractElements';


const TopDescarcariComponent = () => {
  const [topDescarcari, setTopDescarcari] = useState([]);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [period, setPeriod] = useState('1M');

  const fetchTopDescarcari = async (startDate, endDate) => {
    try {
      const response = await axios.get(`${API_URL}users/widgets/top-descarcari`, {
        params: {
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
        }
      });
      setTopDescarcari(response.data);
    } catch (error) {
      console.error('Error fetching top descarcari data:', error);
    }
  };

  useEffect(() => {
    fetchTopDescarcari(startDate, endDate);
  }, [startDate, endDate]);

  const handlePeriodChange = (item) => {
    let newStartDate = new Date();
    let newEndDate = new Date();

    switch (item) {
      case '1D':
        newStartDate.setDate(newEndDate.getDate());
        break;
      case '1W':
        newStartDate.setDate(newEndDate.getDate() - 7);
        break;
      case '1M':
        newStartDate = new Date(newEndDate.getFullYear(), newEndDate.getMonth(), 1);
        break;
      case '1T':
        const currentMonth = newEndDate.getMonth();
        const startMonth = currentMonth - (currentMonth % 3);
        newStartDate = new Date(newEndDate.getFullYear(), startMonth, 1);
        break;
      case '1Y':
        newStartDate = new Date(newEndDate.getFullYear(), 0, 1);
        break;
      default:
        break;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setPeriod(item);
    fetchTopDescarcari(newStartDate, newEndDate);
  };

  const periods = ['1D', '1W', '1M', '1T', '1Y'];

  return (
    <Card className='descarcari'>
      <CardHeader>
        <h5> Top 10 Descărcări</h5>
        <div className='card-header-right-icon'>
        <div className="header-top">
          <div className='card-header-right-icon'>
            <Nav tabs className='custom-tab'>
              {periods.map((item, i) => (
                <NavItem key={i}>
                  <NavLink tag='button' onClick={() => handlePeriodChange(item)} className={`nav-link ${period === item && 'active'}`}>
                    {item}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </div>
          <div className='d-flex2'>
            <Input
              type="date"
              className='form-control form-control-sm'
              value={startDate.toISOString().split('T')[0]}
              onChange={(e) => setStartDate(new Date(e.target.value))}
            />
            <Input
              type="date"
              className='form-control form-control-sm'
              value={endDate.toISOString().split('T')[0]}
              onChange={(e) => setEndDate(new Date(e.target.value))}
            />
          </div>
        </div>
        </div>
        
      </CardHeader>
      <CardBody>
      <ul className="list-group lessons-lists">
        <li className="list-group-item d-flex justify-content-between align-items-center font-weight-bold">
          <span className="w-50 text-center">Auto</span>
          <span className="w-50 text-center">Numar descarcari</span>
        </li>
        {topDescarcari.map((item, index) => (
          <li key={index} className="list-group-item d-flex justify-content-between align-items-center" >
            <div>
                <H6 className='f-14 f-w-400 mb-0'>{item.nr_vehicul}</H6>
                <span className='f-light'></span>
              </div>
            <div className=' ms-auto'>
                    {item.descarcari} descărcări
              </div>
          </li>




        ))}
      </ul>
      </CardBody>
    </Card>
  );
};

export default TopDescarcariComponent;
