import React, { useState, useEffect, Fragment, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { Nav, NavItem, NavLink, Input, Card, CardHeader, CardBody } from 'reactstrap';
import { H4, Btn,H5 } from '../../../AbstractElements';
import { API_URL } from '../../../config';
import axios from 'axios';

const DataTableComponent = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [period, setPeriod] = useState('1M');
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [cardInfo, setCardInfo] = useState({ isOpen: false, date: '', sector: '', content: [] });

  const fetchData = async (startDate, endDate) => {
    try {
      console.log('Fetching data from API:', { startDate, endDate });
      const response = await axios.get(`${API_URL}users/widgets/organized-table-data`, {
        params: {
          rol: localStorage.getItem('rol'),
          nume: localStorage.getItem('nume'),
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
        }
      });
      const apiData = response.data;
      console.log('Data received from API:', apiData);
      setTableData(apiData.tableData);
      setTableColumns([
        { 
          name: 'Date', 
          selector: row => row.date, 
          sortable: true 
        },
        ...apiData.sectors.map(sector => ({
          name: sector, 
          selector: row => row[sector], 
          sortable: true,
          cell: (row) => {
            const sectorName = sector;
            return (
              <div
                onMouseEnter={() => showCardInfo(row.date, sectorName)}
                onMouseLeave={() => setCardInfo({ isOpen: false, date: '', sector: '', content: [] })}
                style={{ position: 'relative', display: 'inline-block' }}
              >
                {row[sectorName]}
              </div>
            );
          }
        }))
      ]);
    } catch (error) {
      console.error('Error fetching table data:', error);
    }
  };

  useEffect(() => {
    console.log('Fetching data with:', { startDate, endDate });
    fetchData(startDate, endDate);
  }, [startDate, endDate, period]);

  useEffect(() => {
    console.log('Table columns set:', tableColumns);
  }, [tableColumns]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleAcceptInfo = async () => {
    try {
      const response = await axios.post(`${API_URL}users/widgets/accept-info`, {
        selectedRows
      });
      if (response.status === 200) {
        alert('Cantitatile  au fost aprobate, ele se vor regasi in rapoartele elaborate.');
        setToggleCleared(!toggleCleared);
      }
    } catch (error) {
      console.error('Am intampinat o problema in aprobarea datelor:', error);
    }
  };

  const handlePeriodChange = (item) => {
    let newStartDate = new Date();
    let newEndDate = new Date();
    
    switch (item) {
      case '1D':
        newStartDate.setDate(newEndDate.getDate());
        break;
      case '1W':
        newStartDate.setDate(newEndDate.getDate() - 7);
        break;
      case '1M':
        newStartDate = new Date(newEndDate.getFullYear(), newEndDate.getMonth(), 1);
        break;
      case '1T':
        const currentMonth = newEndDate.getMonth();
        const startMonth = currentMonth - (currentMonth % 3);
        newStartDate = new Date(newEndDate.getFullYear(), startMonth, 1);
        break;
      case '1Y':
        newStartDate = new Date(newEndDate.getFullYear(), 0, 1);
        break;
      default:
        break;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setPeriod(item);
    fetchData(newStartDate, newEndDate);
  };

  const showCardInfo = async (date, sector) => {
    try {
      const response = await axios.get(`${API_URL}users/widgets/popover-data`, {
        params: {
          date: date,
          sector: sector
        }
      });
      setCardInfo({
        isOpen: true,
        date: date,
        sector: sector,
        content: response.data
      });
    } catch (error) {
      console.error('Error fetching card data:', error);
    }
  };

  const periods = ['1D', '1W', '1M', '1T', '1Y'];

  return (
    <Fragment>
      <CardHeader> <H5>  Informatii zilnice total cantitati depozitate</H5>
          <div className='card-header-right-icon'>
          <div className="header-top">
        <div className='card-header-right-icon'>
          <Nav tabs className='custom-tab'>
            {periods.map((item, i) => (
              <NavItem key={i}>
                <NavLink tag='button' onClick={() => handlePeriodChange(item)} className={`nav-link ${period === item && 'active'}`}>
                  {item}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
        <div className='d-flex'>
          <Input
            type="date"
            className='form-control form-control-sm'
            value={startDate.toISOString().split('T')[0]}
            onChange={(e) => setStartDate(new Date(e.target.value))}
          />
          <Input
            type="date"
            className='form-control form-control-sm'
            value={endDate.toISOString().split('T')[0]}
            onChange={(e) => setEndDate(new Date(e.target.value))}
          />
        </div>
      </div>
                </div>
              </CardHeader>
              <CardBody>
     

      {selectedRows.length > 0 && (
        <div className={`d-flex align-items-center justify-content-between  p-2`}>
          <H4 attrH4={{ className: 'text-muted m-0' }}>Accepta date!</H4>
          <Btn attrBtn={{ color: 'primary', onClick: handleAcceptInfo }}>Accepta</Btn>
        </div>
      )}

      <DataTable
        data={tableData}
        columns={tableColumns}
        striped={true}
        center={true}
        pagination
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
      />

      {cardInfo.isOpen && (
        <Card style={{ position: 'fixed', bottom: '10px', right: '10px', width: '500px', zIndex: 1000 }} className='info-fix'>
          <CardHeader>
            <div className='card-header-right-icon'>
            {cardInfo.sector.charAt(0).toUpperCase() + cardInfo.sector.slice(1)} - {cardInfo.date}
            </div>
          </CardHeader>
          <CardBody>
            {cardInfo.content.map((item, index) => (
              <p key={index}><mark>{item.total_kg_net} kg</mark> <small>{item.cod_deseu}</small></p>
            ))}
          </CardBody>
        </Card>
      )}
      </CardBody>
    </Fragment>
  );
};

export default DataTableComponent;
