import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Card, CardBody, CardHeader, Input, Nav, NavItem, NavLink, Col, Row } from 'reactstrap';
import { H3, H4, H5, P, Image } from '../../../AbstractElements';
import siglaSector1 from '../../../assets/images/other-images/sigla_p_1.png';
import siglaSector2 from '../../../assets/images/other-images/sigla_p_2.png';
import siglaSector3 from '../../../assets/images/other-images/sigla_p_3.png';
import siglaSector4 from '../../../assets/images/other-images/sigla_p_4.png';
import siglaSector5 from '../../../assets/images/other-images/sigla_p_5.png';
import siglaSector6 from '../../../assets/images/other-images/sigla_p_6.png';

import { API_URL } from '../../../config';

const SectorsCard = () => {
  const [sectors, setSectors] = useState([]);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [period, setPeriod] = useState('1M');
  const sectorImages = {
    1: siglaSector1,
    2: siglaSector2,
    3: siglaSector3,
    4: siglaSector4,
    5: siglaSector5,
    6: siglaSector6,
  };
  

  useEffect(() => {
    fetchSectorsData();
  }, [startDate, endDate]);

  const fetchSectorsData = async () => {
    try {
      const response = await axios.get(`${API_URL}/data/widgets/getSectorsData`, {
        params: { startDate: startDate.toISOString().split('T')[0], endDate: endDate.toISOString().split('T')[0] }
      });
      setSectors(response.data);
    } catch (error) {
      console.error('Error fetching sectors data:', error);
    }
  };

  const handlePeriodChange = (item) => {
    let newStartDate = new Date();
    let newEndDate = new Date();

    switch (item) {
      case '1D':
        newStartDate.setDate(newEndDate.getDate());
        break;
      case '1W':
        newStartDate.setDate(newEndDate.getDate() - 7);
        break;
      case '1M':
        newStartDate = new Date(newEndDate.getFullYear(), newEndDate.getMonth(), 1);
        break;
      case '1T':
        const currentMonth = newEndDate.getMonth();
        const startMonth = currentMonth - (currentMonth % 3);
        newStartDate = new Date(newEndDate.getFullYear(), startMonth, 1);
        break;
      case '1Y':
        newStartDate = new Date(newEndDate.getFullYear(), 0, 1);
        break;
      default:
        break;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setPeriod(item);
  };

  const periods = ['1D', '1W', '1M', '1T', '1Y'];

  return (
    <Col xxl='8' xl='8' md='8' sm='12' className='d-flex'>
      <Card className="flex-fill">
      
        <CardHeader className='card-no-border'>
          <div className="header-top">
            <h5>Sectoare</h5>
            <div className='card-header-right-icon'>
              <Nav tabs className='custom-tab'>
                {periods.map((item, i) => (
                  <NavItem key={i}>
                    <NavLink tag='button' onClick={() => handlePeriodChange(item)} className={`nav-link ${period === item && 'active'}`}>
                      {item}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </div>
            <div className='d-flex'>
              <Input
                type="date"
                className='form-control form-control-sm'
                value={startDate.toISOString().split('T')[0]}
                onChange={(e) => setStartDate(new Date(e.target.value))}
              />
              <Input
                type="date"
                className='form-control form-control-sm'
                value={endDate.toISOString().split('T')[0]}
                onChange={(e) => setEndDate(new Date(e.target.value))}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody className='pt-0'>
         <Row className='lessons-lists'>
            {sectors.map((sector, index) => (
              
            <Col xs='6'  key={index} className='list-group-item p-3'>
                <Row>
                    <Col md='2'>
                      <Image attrImage={{ body: true, className: 'img-50 rounded-circle', src: sectorImages[sector.provenienta.replace('sector ', '')], alt: 'logo-primarie-sector' }} />
                    </Col>
                    <Col mc='5'>
                        <div>
                          <H5 className='f-14 f-w-400 mb-0'> {sector.provenienta.charAt(0).toUpperCase() + sector.provenienta.slice(1)}                 </H5>
                          <span className=''>Ultima descarcare:<bR></bR>{sector.ultima_depozitare}</span>
                        </div>
                    </Col>
                    <Col md='5' className='align-content-center'>
                        {sector.total_kg_net.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} kg
                    </Col>

                </Row>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SectorsCard;
