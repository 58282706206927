// DailyDataChart.js
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import ConfigDB from "../../../Config/ThemeConfig";
const primary = ConfigDB.data.color.primary_color;


const DailyDataChart = ({ data }) => {
  const chartOptions = {
        chart: {
          type: "bar",
          height: 200,
          stacked: true,
          toolbar: {
            show: false,
          },
          dropShadow: {
            enabled: true,
            top: 8,
            left: 0,
            blur: 10,
            color: "#7064F5",
            opacity: 0.1,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20px",
            borderRadius: 0,
          },
        },
        grid: {
          show: true,
          borderColor: "var(--chart-border)",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          dashArray: 0,
          lineCap: "butt",
          colors: "#fff",
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        states: {
          hover: {
            filter: {
              type: "darken",
              value: 1,
            },
          },
        },
    
        colors: ["#2A7E21","#2A7E21" ],
        yaxis: {
          tickAmount: 3,
          labels: {
            show: true,
            style: {
              fontFamily: "Rubik, sans-serif",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        xaxis: {
          categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21","22","23","24","25","26","27","28","29","30","31"],
          labels: {
            style: {
              fontFamily: "Rubik, sans-serif",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
    
        responsive: [
          {
            breakpoint: 1661,
            options: {
              chart: {
                height: 150,
              },
            },
          },
          {
            breakpoint: 767,
            options: {
              plotOptions: {
                bar: {
                  columnWidth: "35px",
                },
              },
              yaxis: {
                labels: {
                  show: false,
                },
              },
            },
          },
          {
            breakpoint: 481,
            options: {
              chart: {
                height: 200,
              },
            },
          },
          {
            breakpoint: 420,
            options: {
              chart: {
                height: 170,
              },
              plotOptions: {
                bar: {
                  columnWidth: "40px",
                },
              },
            },
          },
        ],
    
  };

  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={[
          { name: 'Inregistrari', data: data.records },
          { name: 'KG Net', data: data.kgNet },
        ]}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default DailyDataChart;
