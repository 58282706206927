import React, { useState } from 'react';
import { Input, Button, Form, FormGroup, Row, Col, Container } from 'reactstrap';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { API_URL } from '../../../config';
import { Typewriter } from 'react-simple-typewriter';

const AssistantComponent = () => {
    const [query, setQuery] = useState('');
    const [responses, setResponses] = useState([]); // Modificat pentru a stoca array de răspunsuri și întrebări
    const [rezultat, setRezultat] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleQueryChange = (event) => {
        setQuery(event.target.value);
    };

    const handleQuerySubmit = async () => {
        setLoading(true);
        try {
            const result = await axios.post(`${API_URL}data/widgets/queryAssistant`, { query });
            const newResponse = { question: query, answer: result.data.answer };

            setResponses([newResponse, ...responses]); // Adaugă noul răspuns deasupra celor vechi
            setQuery(''); // Golește câmpul de input

            if (result.data.data.length > 0) {
                const columns = Object.keys(result.data.data[0]).map(key => ({
                    name: key,
                    selector: row => row[key],
                    sortable: true,
                }));
                setTableColumns(columns);
                setRezultat(result.data.data);
            } else {
                setRezultat([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const formatResponse = (responses) => {
        return responses.map((response, index) => (
            <div key={index} className="mb-3">
                <p><strong>Întrebare:</strong></p>
                <p>{response.question}</p>
                <p><strong>Răspuns:</strong></p>
                <div>
                    {response.answer.map((line, lineIndex) => {
                        // Înlocuiește caracterele de linie nouă cu elemente HTML corespunzătoare
                        const formattedLines = line.split('\n').map((lineContent, subIndex) => {
                            // Detectează și formatează listele
                            if (lineContent.startsWith('- ')) {
                                return <div key={`${index}-${lineIndex}-${subIndex}`} style={{ marginLeft: '20px' }}>{lineContent.substring(2)}</div>;
                            } 
                            // Detectează și formatează formulele matematice
                            else if (lineContent.startsWith('\\[') && lineContent.endsWith('\\]')) {
                                return <p key={`${index}-${lineIndex}-${subIndex}`}><em>{lineContent}</em></p>;
                            }
                            return <p key={`${index}-${lineIndex}-${subIndex}`}>{lineContent}</p>;
                        });

                        return <div key={`${index}-${lineIndex}`}>{formattedLines}</div>;
                    })}
                </div>
            </div>
        ));
    };

    return (
        <Container className="mt-4">
            <Form inline className="mb-4">
                <FormGroup className="d-flex align-items-center w-100">
                    <Input
                        type="text"
                        value={query}
                        onChange={handleQueryChange}
                        placeholder="Introduceți întrebarea dvs."
                        className="flex-grow-1 mr-2"
                    />
                    <Button onClick={handleQuerySubmit} color="primary">
                        Trimite
                    </Button>
                </FormGroup>
            </Form>
            <Row>
                <Col>
                    <div className="response mt-4">
                        {loading ? (
                            <div className="typing-effect">
                                <Typewriter
                                    words={['Se procesează...']}
                                    loop={false}
                                    cursor
                                    cursorStyle="_"
                                    typeSpeed={50}
                                    deleteSpeed={50}
                                    delaySpeed={1000}
                                />
                            </div>
                        ) : (
                            <div>{formatResponse(responses)}</div>
                        )}
                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <DataTable
                        data={rezultat}
                        columns={tableColumns}
                        striped={true}
                        center={true}
                        pagination
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default AssistantComponent;
