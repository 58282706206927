import React, { useEffect, useState } from 'react';
import { getUsers, deleteUser, updateUser, createUser } from '../../../../Services/userService';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { toast } from 'react-toastify';

const DataTables = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterRole, setFilterRole] = useState('');
    const [currentUser, setCurrentUser] = useState({
        id: '',
        nume: '',
        email: '',
        telefon: '',
        parola: '',
        rol: 'depozitar',
        activ: true,
    });

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await getUsers();
            setUsers(response.data);
            setFilteredUsers(response.data);
        } catch (error) {
            toast.error('Eroare la preluarea utilizatorilor.');
        }
    };

    const toggleEditModal = () => setEditModal(!editModal);

    const handleEditClick = (user) => {
        setCurrentUser({
            id: user.id,
            nume: user.nume,
            email: user.email,
            telefon: user.telefon,
            parola: '',
            rol: user.rol,
            activ: user.activ,
        });
        setIsEditMode(true);
        toggleEditModal();
    };

    const handleDeleteClick = async (id) => {
        try {
            await deleteUser(id);
            setUsers(users.filter(user => user.id !== id));
            setFilteredUsers(filteredUsers.filter(user => user.id !== id));
            toast.success('Utilizator șters cu succes!');
        } catch (error) {
            toast.error('Eroare la ștergerea utilizatorului.');
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEditMode) {
                await updateUser(currentUser.id, currentUser);
                setUsers(users.map(user => (user.id === currentUser.id ? currentUser : user)));
                setFilteredUsers(filteredUsers.map(user => (user.id === currentUser.id ? currentUser : user)));
                toast.success('Utilizator actualizat cu succes!');
            } else {
                await createUser(currentUser);
                fetchUsers();
                toast.success('Utilizator adăugat cu succes!');
            }
            toggleEditModal();
        } catch (error) {
            toast.error('Eroare la salvarea utilizatorului.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentUser({ ...currentUser, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCurrentUser({ ...currentUser, [name]: checked });
    };

    const handleAddUserClick = () => {
        setCurrentUser({
            id: '',
            nume: '',
            email: '',
            telefon: '',
            parola: '',
            rol: 'depozitar',
            activ: true,
        });
        setIsEditMode(false);
        toggleEditModal();
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        filterUsers(e.target.value, filterRole);
    };

    const handleFilterRoleChange = (e) => {
        setFilterRole(e.target.value);
        filterUsers(searchTerm, e.target.value);
    };

    const filterUsers = (searchTerm, filterRole) => {
        let filtered = users;
        if (searchTerm) {
            filtered = filtered.filter(user =>
                user.nume.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.telefon.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        if (filterRole) {
            filtered = filtered.filter(user => user.rol === filterRole);
        }
        setFilteredUsers(filtered);
    };

    const handleRefreshClick = () => {
        setSearchTerm('');
        setFilterRole('');
        fetchUsers();
    };

    return (
        <div>
            <div className="mb-3">
                <Button color="primary" onClick={handleAddUserClick} className="mr-2">Adaugă Utilizator</Button>
                <Button color="secondary" onClick={handleRefreshClick}>Reîncarcă</Button>
            </div>
            <div className="mb-3 d-flex justify-content-between">
                <Input
                    type="text"
                    placeholder="Caută utilizator..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="mr-2"
                />
                <Input
                    type="select"
                    name="rol"
                    id="filterRole"
                    value={filterRole}
                    onChange={handleFilterRoleChange}
                >
                    <option value="">Toate Rolurile</option>
                    <option value="administrator">Administrator</option>
                    <option value="primaria_general">Primăria Generală</option>
                    <option value="primarie_sector">Primărie Sector</option>
                    <option value="depozitar">Depozitar</option>
                    <option value="sortator">Sortator</option>
                </Input>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nume</th>
                        <th>Email</th>
                        <th>Telefon</th>
                        <th>Rol</th>
                        <th>Activ</th>
                        <th>Acțiuni</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredUsers.map((user, index) => (
                        <tr key={user.id}>
                            <th scope="row">{index + 1}</th>
                            <td>{user.nume}</td>
                            <td>{user.email}</td>
                            <td>{user.telefon}</td>
                            <td>{user.rol}</td>
                            <td>{user.activ ? 'Da' : 'Nu'}</td>
                            <td>
                                <Button color="warning" size="sm" onClick={() => handleEditClick(user)}>Editare</Button>{' '}
                                <Button color="danger" size="sm" onClick={() => handleDeleteClick(user.id)}>Ștergere</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal isOpen={editModal} toggle={toggleEditModal}>
                <ModalHeader toggle={toggleEditModal}>{isEditMode ? 'Editare Utilizator' : 'Adăugare Utilizator'}</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleEditSubmit}>
                        <FormGroup>
                            <Label for="nume">Nume</Label>
                            <Input type="text" name="nume" id="nume" value={currentUser.nume} onChange={handleInputChange} required />
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input type="email" name="email" id="email" value={currentUser.email} onChange={handleInputChange} required />
                        </FormGroup>
                        <FormGroup>
                            <Label for="telefon">Telefon</Label>
                            <Input type="text" name="telefon" id="telefon" value={currentUser.telefon} onChange={handleInputChange} required />
                        </FormGroup>
                        <FormGroup>
                            <Label for="parola">Parola</Label>
                            <Input type="password" name="parola" id="parola" value={currentUser.parola} onChange={handleInputChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="rol">Rol</Label>
                            <Input type="select" name="rol" id="rol" value={currentUser.rol} onChange={handleInputChange} required>
                                <option value="administrator">Administrator</option>
                                <option value="primaria_general">Primăria Generală</option>
                                <option value="primarie_sector">Primărie Sector</option>
                                <option value="depozitar">Depozitar</option>
                                <option value="sortator">Sortator</option>
                            </Input>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" name="activ" checked={currentUser.activ} onChange={handleCheckboxChange} />{' '}
                                Activ
                            </Label>
                        </FormGroup>
                        <ModalFooter>
                            <Button color="primary" type="submit">Salvează</Button>{' '}
                            <Button color="secondary" onClick={toggleEditModal}>Anulează</Button>
                        </ModalFooter>
                    </Form>
                </ModalBody>
            </Modal>
        </div>

    );
};

export default DataTables;