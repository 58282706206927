// Dashboard.js
import { Row, Col, Card, CardBody,CardHeader, FormGroup, Label, Input} from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { H5, UL, LI } from '../../../AbstractElements';
import { Earning, Expense, OverallBalanceTitle } from '../../../Constant';
import LightCardBox from './LightCardBox';
import ReactApexChart from 'react-apexcharts';
import { CurrencyChartData } from '../../../Data/DefaultDashboard/Chart';
import { LightCardData } from '../../../Data/DefaultDashboard';
import axios from 'axios';
import DailyDataChart from './DailyDataChart';
import { API_URL } from '../../../config';
import DropdownCommon from '../../Common/Dropdown3';


const Dashboard = () => {
  const [data, setData] = useState({
    categories: [],
    records: [],
    kgNet: [],
    totalRecords: 0,
    totalKgNet: 0,
  });
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Luna curentă

  const fetchData = async (month) => {
    try {
      const response = await axios.get(`${API_URL}users/widgets/monthlydata`, {
        params: {
          rol: localStorage.getItem('rol'),
          nume: localStorage.getItem('nume'),
          month
        }
      });
      const apiData = response.data;

      // Procesarea datelor pentru grafic
      const categories = Object.keys(apiData);
      const records = categories.map(day => apiData[day].total_records);
      const kgNet = categories.map(day => apiData[day].total_kg_net);
      const totalRecords = records.reduce((acc, item) => acc + item, 0);
      const totalKgNet = kgNet.reduce((acc, item) => acc + item, 0);

      setData({ categories, records, kgNet, totalRecords, totalKgNet });
    } catch (error) {
      console.error("Eroare la preluarea datelor:", error);
    }
  };

  useEffect(() => {
    fetchData(selectedMonth);
  }, [selectedMonth]);

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption.value);
  };
 

  const months = [
    { value: 1, label: 'Ianuarie' },
    { value: 2, label: 'Februarie' },
    { value: 3, label: 'Martie' },
    { value: 4, label: 'Aprilie' },
    { value: 5, label: 'Mai' },
    { value: 6, label: 'Iunie' },
    { value: 7, label: 'Iulie' },
    { value: 8, label: 'August' },
    { value: 9, label: 'Septembrie' },
    { value: 10, label: 'Octombrie' },
    { value: 11, label: 'Noiembrie' },
    { value: 12, label: 'Decembrie' },
  ];

  const LightCardData = [
    {
      title: 'Inregistrări',
      price: data.totalRecords,
      color: 'success',
      icon: 'fa-database',
      gros: ''
    },
    {
      title: 'KG Net',
      price: data.totalKgNet,
      color: 'danger',
      icon: 'fa-balance-scale',
      gros: ''
    }
  ];

  return (
   <Col xxl='12' lg='12' className='box-col-12   d-flex'>
<Card  className="flex-fill">
  <CardHeader className='card-no-border'>
  <div class="header-top">
    <H5>Date la zi</H5>
    <div className='card-header-right-icon'>
    <DropdownCommon 
                    icon={false} 
                    options={months.map(month => ({
                      label: month.label,
                      value: month.value
                    }))}
                    onChange={handleMonthChange}
                    btn={{ caret: true }}
                  />
                  </div>
                  </div>
  </CardHeader>
  <CardBody className='pt-0'>
    <Row className='m-0 overall-card'>
    <Col xl='9'>
    <DailyDataChart data={data} />
    </Col>
    <Col xl='3'>
      {LightCardData.map((data, i) => (
          <Col key={i} xl='12' md='2'>
            <LightCardBox data={data} />
          </Col>
        ))}
        </Col>
    </Row>
  </CardBody>
</Card>
</Col>
  );
};

export default Dashboard;
