import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import { classes } from "../Data/Layouts";

const isTokenExpired = (token) => {
  if (!token) return true;

  const payload = JSON.parse(atob(token.split('.')[1]));
  const currentTime = Date.now() / 1000;

  return false;
};

const Routers = () => {
  const [login, setLogin] = useState(JSON.parse(localStorage.getItem("login")));
  const [authenticated, setAuthenticated] = useState(false);
  const defaultLayoutObj = classes.find((item) => Object.values(item).pop(1) === "compact-wrapper");
  const layout = localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  useEffect(() => {
    const token = localStorage.getItem("token");
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    if (isTokenExpired(token)) {
      localStorage.removeItem("login");
      localStorage.removeItem("authenticated");
      localStorage.removeItem("token");
      setLogin(false);
      setAuthenticated(false);
    } else {
      setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    }
  }, []);

  return (
    <BrowserRouter basename="/">
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            {login || authenticated ? (
              <>
                <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/default/`} />} />
                <Route exact path="/" element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/default/`} />} />
              </>
            ) : (
              <Route path="/" element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />} />
            )}
            <Route path="/*" element={<LayoutRoutes />} />
          </Route>

          <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />
          {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={<Component />} key={i} />
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
