import React, { useState, Fragment } from 'react';
import { Input, Button, Container, Row, Card, CardBody, CardHeader, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Breadcrumbs, H6, UL, P, LI,Btn } from '../../../AbstractElements';
import axios from 'axios';
import { API_URL } from '../../../config';
import { Typewriter } from 'react-simple-typewriter';
import { useTranslation } from 'react-i18next';

const ReportComponent = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [period, setPeriod] = useState('1M');
  const [sector, setSector] = useState('sector 1');
  const [reportData, setReportData] = useState([]);
  const [totalToneTaxa, setTotalToneTaxa] = useState(0);
  const [totalToneTarif, setTotalToneTarif] = useState(0);
  const [loading, setLoading] = useState(false);
  const [reportImage, setReportImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(`Buna ziua,\n\nAcesta este raportul aferent pentru ${sector}.\n\nCu respect,\nEchipa noastra`);

  const handlePeriodChange = (item) => {
    let newStartDate = new Date();
    let newEndDate = new Date();

    switch (item) {
      case '1D':
        newStartDate.setDate(newEndDate.getDate());
        break;
      case '1W':
        newStartDate.setDate(newEndDate.getDate() - 7);
        break;
      case '1M':
        newStartDate = new Date(newEndDate.getFullYear(), newEndDate.getMonth(), 1);
        break;
      case '1T':
        const currentMonth = newEndDate.getMonth();
        const startMonth = currentMonth - (currentMonth % 3);
        newStartDate = new Date(newEndDate.getFullYear(), startMonth, 1);
        break;
      case '1Y':
        newStartDate = new Date(newEndDate.getFullYear(), 0, 1);
        break;
      default:
        break;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setPeriod(item);
  };

  const periods = ['1D', '1W', '1M', '1T', '1Y'];
  const sectors = [
    'sector 1',
    'sector 2',
    'sector 3',
    'sector 4',
    'sector 5',
    'sector 6'
  ];

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/data/getReportData`, { startDate, endDate, sector, returnFilePath: false });
      const { data, totalToneTaxa, totalToneTarif } = response.data;

      if (data && data.length > 0) {
        setReportData(data);
        setTotalToneTaxa(totalToneTaxa);
        setTotalToneTarif(totalToneTarif);
      } else {
        setReportData([]);
        setTotalToneTaxa(0);
        setTotalToneTarif(0);
        console.warn('No data available for the selected period.');
      }

      // Generăm raportul JPEG
      const reportResponse = await axios.post(`${API_URL}/data/getReportData`, { startDate, endDate, sector, exportType: 'jpg' }, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([reportResponse.data]));
      setReportImage(url);
    } catch (error) {
      console.error('Error fetching report data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadPDF = async () => {
    try {
      const response = await axios.post(`${API_URL}/data/getReportData`, { startDate, endDate, sector, exportType: 'pdf' }, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Raport_Sectoare.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading PDF report:', error);
    }
  };

  const handleSendReport = async () => {
    try {
      const reportDescription = `
            Acest raport tehnic pentru sectorul ${sector} acoperă perioada ${startDateString} - ${endDateString}. 
            Include date detaliate despre taxele și tarifele colectate, împreună cu metrici relevante pentru analiza performanței sectorului. 
            Raportul este generat automat și conține informații esențiale pentru monitorizarea și evaluarea activităților de gestionare a deșeurilor. 
            Toate datele sunt agregate și prezentate într-un format ușor de înțeles, facilitând astfel luarea deciziilor informate. 
            Pentru întrebări sau clarificări suplimentare, vă rugăm să ne contactați.
            Raport generat la data de ${new Date().toLocaleString()}.
            `;
      setMessage(reportDescription);

      await axios.post(`${API_URL}/data/sendReport`, { startDate, endDate, sector, email, message });
      // Logica pentru adăugarea în operation_logs
      // Adăugați aici logica pentru semafor 0 și identificator operation_type
    } catch (error) {
      console.error('Error sending report:', error);
    } finally {
      setModalOpen(false);
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    // Preluarea emailului din baza de date
    axios.post(`${API_URL}/data/getEmailBySector`, { sector })
      .then(response => {
        setEmail(response.data.email);
      })
      .catch(error => {
        console.error('Error fetching email:', error);
      });
  };

  // Convertește obiectele Date în string-uri
  const startDateString = startDate.toLocaleDateString();
  const endDateString = endDate.toLocaleDateString();

  return (
    <Fragment>
      <Breadcrumbs parent="Raportare" title="DRaportare" mainTitle="Generare rapoarte" />
      <Container fluid={true}>
        <Row className="project-card">
          <Card className='descarcari'>
            <CardHeader>
              <div className='input-group flex-nowrap'>
                <Nav tabs className='custom-tab'>
                  {periods.map((item, i) => (
                    <NavItem key={i}>
                      <NavLink tag='button' onClick={() => handlePeriodChange(item)} className={`nav-link ${period === item && 'active'}`}>
                        {item}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <Input
                  type="select"
                  className='form-control form-control-sm'
                  value={sector}
                  onChange={(e) => setSector(e.target.value)}
                >
                  {sectors.map((sector, i) => (
                    <option key={i} value={sector}>{sector}</option>
                  ))}
                </Input>
                <Input
                  type="date"
                  className='form-control form-control-sm'
                  value={startDate.toISOString().split('T')[0]}
                  onChange={(e) => setStartDate(new Date(e.target.value))}
                />
                <Input
                  type="date"
                  className='form-control form-control-sm'
                  value={endDate.toISOString().split('T')[0]}
                  onChange={(e) => setEndDate(new Date(e.target.value))}
                />
                <span className='btn btn-primary input-group-text' onClick={handleSubmit}>Genereaza</span>
              </div>
            </CardHeader>
            <CardBody>
              {loading ? (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <Typewriter
                    words={[t('Generam raportul...')]}
                    loop={0}
                    cursor
                    cursorStyle='_'
                    typeSpeed={70}
                    deleteSpeed={50}
                    delaySpeed={1000}
                  />
                </div>
              ) : (
                <div style={{ display: 'flex', alignItems: 'stretch' }}>
                  {reportImage && (
                    <>
                      <div style={{ flex: '1', marginRight: '20px' }} className='my-gallery'>
                        <img 
                          src={reportImage} 
                          alt="Raport" 
                          style={{ width: '800px', cursor: 'pointer' }} 
                          onClick={() => window.open(reportImage, '_blank')}
                          className='img-thumbnail media'
                        />
                      </div>
                      <div style={{ flex: '1', textAlign: 'left' }} className='activity-dot-undefined' >
                        <div className='blog-details'>
                          <div className='blog-date digits'>{startDateString} - {endDateString}</div>
                          <H6><b>Raport pentru {sector}</b></H6>
                          <div className='blog-bottom-content'>
                            <UL attrUL={{ className: 'blog-social flex-row' }}>
                              <LI>Acest raport detaliat pentru  {sector} acoperă perioada {startDateString} - {endDateString}. 
                                        Include date detaliate despre taxele și tarifele colectate, împreună cu metrici relevanti pentru analiza performanței sectorului. 
                                        Raportul este generat automat și conține informații esențiale pentru monitorizarea și evaluarea activităților de gestionare a deșeurilor. 
                                        Toate datele sunt agregate și prezentate într-un format ușor de înțeles, facilitând astfel luarea deciziilor informate. 
                                        Pentru întrebări sau clarificări suplimentare, vă rugăm să ne contactați.
                                        Raport generat la data de {new Date().toLocaleString()}.</LI>
                              
                            </UL>
                            <hr />

                            <Btn attrBtn={{ className: 'btn me-1', color: 'primary', type: 'button', onClick: handleDownloadPDF }}>
                              <span><i className="fa fa-cloud-download"></i></span> Descarcă fișierul PDF
                            </Btn>
                            <Btn  attrBtn={{ className: 'btn me-1', color: 'primary', type: 'button', onClick: handleOpenModal }}>
                                <span><i className="fa fa-share" ></i></span> Trimite raportul
                              </Btn>
                            
                          </div>
                        </div>
                        
                        
                      </div>
                    </>
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </Row>
      </Container>

      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Trimite Raportul către {sector}</ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSendReport}>Trimite</Button>{' '}
          <Button color="secondary" onClick={() => setModalOpen(!modalOpen)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ReportComponent;
