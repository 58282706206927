import { Row, Col, Card, CardBody,CardHeader, FormGroup, Label, Input} from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { H5, H6, UL, LI, P } from '../../../AbstractElements';
import { Earning, Expense, OverallBalanceTitle } from '../../../Constant';
import LightCardBox from './LightCardBox';
import ReactApexChart from 'react-apexcharts';
import { CurrencyChartData } from '../../../Data/DefaultDashboard/Chart';
import { LightCardData } from '../../../Data/DefaultDashboard';
import axios from 'axios';
import DailyDataChart from '../../Common/CommonWidgets/DailyDataChart';
import { API_URL } from '../../../config';
import DropdownCommon from '../../Common/Dropdown3';
import ConfigDB from "../../../Config/ThemeConfig";
const primary = ConfigDB.data.color.primary_color;

const UltimeleInregistrari = () => {
  const [entries, setEntries] = useState([]);
  const [filter, setFilter] = useState('today'); // Filtru implicit

  const fetchEntries = async () => {
    try {
      const response = await axios.get(`${API_URL}users/widgets/getLastEntries`, {
        params: {
          rol: localStorage.getItem('rol'),
          nume: localStorage.getItem('name')
        }
      });
      setEntries(response.data);
    } catch (error) {
      console.error("Eroare la preluarea datelor:", error);
    }
  };

  useEffect(() => {
    fetchEntries();
  }, []);

  return (
    <Col xxl='4' xl='5' md='6' sm='7' className='notification   d-flex'>
      <Card className="flex-fill">
        <CardHeader className='card-no-border'>
          <div className='header-top'>
            <H5 attrH5={{ className: 'm-0' }}>Ultimele inregistrari</H5>
           
          </div>
        </CardHeader>
        <CardBody className='pt-0'>
          <UL>
          {entries.map((entry, index) => (
              <LI attrLI={{ className: 'd-flex' }} key={index}>
                <div className={`activity-dot-${entry.color}`} />
                <div className=' ms-3'>
                  <P attrPara={{ className: 'd-flex justify-content-between mb-2' }}>
                    <span className='date-content light-background'>{new Date(entry.data_intrare).toLocaleDateString()} {new Date(entry.data_intrare).toLocaleTimeString()}</span>
                    <span></span>
                  </P>
                  <H6>
                  {entry.furnizor}
                    <span className='dot-notification' />
                  </H6>
                  <p className=''>{entry.provenienta} / {entry.cod_deseu} / {entry.nr_vehicul} / {entry.kg_net} / {entry.contract}</p>
                </div>
              </LI>
            ))}
          </UL>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UltimeleInregistrari;
