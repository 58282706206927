import React, { Fragment, useState, useContext } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P, Image } from "../AbstractElements";
import { EmailAddress, ForgotPassword, Password, RememberPassword, SignIn } from "../Constant";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import CustomizerContext from "../_helper/Customizer";
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom';
import { API_URL } from '../config';
import logoWhite from '../assets/images/logo/logo-samd.png';
import logoDark from '../assets/images/logo/logo-samd.png';

const Signin = ({ selected }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const navigate = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);

  const loginAuth = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}auth/login`, { email, password });

      if (response.data.status === 'success') {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("nume", response.data.nume);
        localStorage.setItem("rol", response.data.rol);
        localStorage.setItem("auth0_profile", JSON.stringify(response.data.profile)); // Set auth0_profile
        
        localStorage.setItem("telefon", response.data.telefon);
        localStorage.setItem("authenticated", "true");
        localStorage.setItem("login", "true");
        toast.success("Autentificare realizată cu succes!");
        navigate(`${process.env.PUBLIC_URL}/dashboard/default/`);
      } else {
        toast.error("A apărut o eroare la autentificare2.");
      }
    } catch (error) {
      toast.error("A apărut o eroare la autentificare.");
    }
  };

  return (
    <Fragment>
      <section>
        <Container fluid={true}>
          <Row>
            <Col xl='7' className='b-center bg-size order-1' style={{ backgroundImage: `url(${require('../assets/images/login/login_bg.webp')})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'block' }}>
              <Image attrImage={{ className: 'bg-img-cover bg-center d-none', src: `${require('../assets/images/login/login_bg.webp')}`, alt: 'loginpage' }} />
            </Col>
            <Col xl='5 p-0'>
              <div className='login-card'>
                <div>
                  <div>
                    <Link className={`logo ${selected ? selected : ''}`} to={process.env.PUBLIC_URL}>
                      <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'loginpage' }} />
                      <Image attrImage={{ className: 'img-fluid for-dark', src: logoDark, alt: 'loginpage' }} />
                    </Link>
                  </div>
                  <div className='login-main'>
                    <Form className="theme-form" onSubmit={loginAuth}>
                      <H4>{selected === "simpleLogin" ? "" : "Autentificare aplicatie"}</H4>
                      <P>{"Introduceti adresa de email si parola pentru autentificare"}</P>
                      <FormGroup>
                        <Label className="col-form-label">{EmailAddress}</Label>
                        <Input className="form-control" type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                      </FormGroup>
                      <FormGroup className="position-relative">
                        <Label className="col-form-label">{Password}</Label>
                        <div className="position-relative">
                          <Input className="form-control" type={togglePassword ? "text" : "password"} onChange={(e) => setPassword(e.target.value)} value={password} />
                          <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                            <span className={togglePassword ? "" : "show"}></span>
                          </div>
                        </div>
                      </FormGroup>
                      <div className="position-relative form-group mb-0">
                        <div className="checkbox">
                          <Input id="checkbox1" type="checkbox" />
                          <Label className="text-muted" for="checkbox1">
                            {RememberPassword}
                          </Label>
                        </div>
                        <a className="link" href="#javascript">
                          {ForgotPassword}
                        </a>
                        <Btn attrBtn={{ color: "primary", className: "d-block w-100 mt-2", type: "submit" }}>{SignIn}</Btn>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ToastContainer />
    </Fragment>
  );
};

export default Signin;
