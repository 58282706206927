import React, { useEffect,Fragment,  useCallback,useContext, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader,TabContent, TabPane, Nav, NavItem, NavLink  } from 'reactstrap';



import { Breadcrumbs, H5, H4,Btn , UL, LI, P} from '../../../AbstractElements';
import ProjectContext from '../../../_helper/Project';

import CustomizerContext from '../../../_helper/Customizer';
import HeaderCard from '../../Common/Component/HeaderCard';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


import ReactApexChart from 'react-apexcharts';
import LightCardBox from './LightCardBox';
import { API_URL } from '../../../config';
import Complex from './complex';
import OpenAIQueryComponent from './OpenAIQueryComponent';
import DinamicData from './DinamicData';
import OpenAIComplet from './OpenAIComplet';

import axios from 'axios';

const Depozitare = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const [activeTab, setActiveTab] = useState('1');
  const { allData } = useContext(ProjectContext);


  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        height: 300,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          color: "#000",
          opacity: 0.08,
        },
      
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
        fontFamily: "Rubik, sans-serif",
        fontSize: "14px",
        fontWeight: 500,
        labels: {
          colors: "var(--chart-text-color)",
        },
        markers: {
          width: 6,
          height: 6,
          radius: 12,
        },
        itemMargin: {
          horizontal: 10,
        },
      },
      stroke: {
        width: [2, 2, 2],
        curve: "smooth",
      },
      grid: {
        show: true,
        borderColor: "var(--chart-border)",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
     
      colors: ["#7064F5", "#54BA4A", "#FF3364", "#F7B924", "#D7263D", "#1B998B"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          opacityFrom: 0,
          opacityTo: 0.1,
          stops: [0, 100],
        },
      },
      dataLabels: {
        enabled: false,
      },
      
     
    
    
    
    
    }
  });
  const [lightCardData, setLightCardData] = useState([]);
  const [period, setPeriod] = useState('1M');

  
  const fetchData = async (period) => {
    try {
      const response = await axios.get(`${API_URL}users/widgets/sector-data`, {
        params: {
          rol: localStorage.getItem('rol'),
          nume: localStorage.getItem('nume'),
          period
        }
      });
      const apiData = response.data;
      const totalRecords = apiData.totalRecords;
      const totalKgNet = apiData.totalKgNet;
        

      

      if (apiData.series && apiData.time_units) {
        let labels = [];

        switch (period) {
          case '1D':
            labels = Array.from({ length: 24 }, (_, i) => `${i}:00`);
            break;
          case '1W':
          case '1M':
            labels = apiData.time_units.map(day => `Day ${day}`);
            break;
          case '1T':
          case '1Y':
          default:
            labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            break;
        }

        setChartData(prevState => ({
          ...prevState,
          series: apiData.series,
          options: {
            ...prevState.options,
            labels
          }
        }));

        

        setLightCardData([
          {
            title: 'Inregistrari',
            icon: 'orders',
            price: totalRecords.toLocaleString(), // Adaugăm valoarea totală pentru Inregistrari
            color: 'success',
          },
          {
            title: 'Tone Net',
            icon: 'expense',
            price: (totalKgNet / 1000).toLocaleString(), // Adaugăm valoarea totală pentru KG Net în tone
            color: 'danger',
          },
        ]);
        // Configure table columns
        const columns = [
          { name: 'Date', selector: row => row.date, sortable: true }
        ];

    
       
        
      } else {
        console.error('Series or time_units is missing in API response:', apiData);
      }
    } catch (error) {
      console.error('Error fetching sector data:', error);
    }
  };

  useEffect(() => {
    fetchData(period);
  }, [period]);

  
   
 
 
  const periods = ['1D', '1W', '1M', '1T', '1Y'];
  return (
    <Fragment>
      <Breadcrumbs parent="Depozitare" title="Depozitare listare" mainTitle="Informatii detaliate" />
      <Container fluid={true}>
        <Row className="project-card">
          <Col md="12" className="project-list">
          <Card>
      <CardHeader className='card-no-border'>
      <div class="header-top">
                            <div className='card-header-right-icon'>
                            <Nav tabs className='custom-tab'>
                              {periods.map((item, i) => (
                                <NavItem key={i}>
                                  <NavLink tag='button' onClick={() => setPeriod(item)} className={`nav-link ${period === item && 'active'}`}>
                                    {item}
                                  </NavLink>
                                </NavItem>
                              ))}
                            </Nav>
                            </div>
                            </div>
       
      </CardHeader>
      <CardBody className='pt-0'>
        <Row className='m-0 overall-card'>
          <Col xl='9' md='8' sm='7' className='box-col-7 p-0'>
            <div className='chart-right'>
              <Row>
                <Col xl='12'>
                  <CardBody className='p-0'>
                  
                    <div className='current-sale-container order-container'>

               
                <ReactApexChart type='bar' height={300} options={chartData.options} series={chartData.series} />
              
                      
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xl='3' md='4' sm='5' className='box-col-5 p-0'>
            <Row className='g-sm-3 g-2'>
                     {lightCardData.map((data, i) => (
                        <Col key={i} md='12'>
                          <LightCardBox data={data} />
                        </Col>
                      ))}
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>

          </Col>
          <Col sm="12">
          <Card>
          <HeaderCard title={'Detalii date inregistrate'} />
          <CardBody>
                            <div className="tabbed-card">
                                <Nav >
                                    <NavItem>
                                        <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                             Date complete
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                            Interogare date SQL AI
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                            Interogare date utilizand AI
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('4')}>
                                             Creaza interogare
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <Complex />
                                    </TabPane>
                                    <TabPane tabId="2">
                                    <OpenAIQueryComponent/>
                                    </TabPane>
                                    <TabPane tabId="3">
                                       <OpenAIComplet/>
                                    </TabPane>
                                    <TabPane tabId="4">
                                          <DinamicData/>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </CardBody>
              
          
            </Card>
          </Col>
          
        </Row>
      </Container>
    </Fragment>
  );
};

export default Depozitare;