import React, { useState } from 'react';
import { Input, Button, Row, Col, Spinner } from 'reactstrap';
import axios from 'axios';
import { API_URL } from '../../../config';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { FaFileCsv, FaFileExcel, FaPaperPlane } from 'react-icons/fa';
import { Tooltip } from 'reactstrap';

const OpenAIQueryComponent = () => {
  const [query, setQuery] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvTooltipOpen, setCsvTooltipOpen] = useState(false);
  const [xlsTooltipOpen, setXlsTooltipOpen] = useState(false);
  const [submitTooltipOpen, setSubmitTooltipOpen] = useState(false);

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleQuerySubmit = async () => {
    setLoading(true); // Set loading to true when the query is submitted
    try {
      const response = await axios.post(`${API_URL}data/widgets/openai-query`, { query });
      const { columns, data } = response.data;

      setTableColumns(columns.map(column => ({
        name: column,
        selector: row => row[column],
        sortable: true,
      })));
      setTableData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false); // Set loading to false when the data fetching is complete
  };

  const exportToCSV = () => {
    const csvData = [
      tableColumns.map(col => col.name),
      ...tableData.map(row => tableColumns.map(col => row[col.name]))
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(csvData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "data_export.csv");
  };

  const exportToXLS = () => {
    const xlsData = [
      tableColumns.map(col => col.name),
      ...tableData.map(row => tableColumns.map(col => row[col.name]))
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(xlsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "data_export.xlsx");
  };

  return (
    <div>
      <Row>
        <Col sm="12">
        <div className='input-group flex-nowrap'>
          <Input 
            type="text" 
            value={query} 
            onChange={handleQueryChange} 
            placeholder="ex: as vrea sa stiu totalul de cantitati pentru saptamana aceasta. Doar acolo unde contract egal tarif. Cu doua zecimale." 
          />
        
          
          <span className='btn btn-primary input-group-text' onClick={handleQuerySubmit}  id="submitIcon">Genereaza</span>

          <FaFileCsv id="csvIcon" onClick={exportToCSV} size={25} className="ml-2" style={{ cursor: 'pointer' }} />
          <FaFileExcel id="xlsIcon" onClick={exportToXLS} size={25} className="ml-2" style={{ cursor: 'pointer' }} />
          <Tooltip placement="top" isOpen={submitTooltipOpen} target="submitIcon" toggle={() => setSubmitTooltipOpen(!submitTooltipOpen)}>
            Trimite interogarea
          </Tooltip>
         
          <Tooltip placement="top" isOpen={xlsTooltipOpen} target="xlsIcon" toggle={() => setXlsTooltipOpen(!xlsTooltipOpen)}>
            Exportă XLS
          </Tooltip>
          </div>
        </Col>
      </Row>
      {loading ? (
        <div className="d-flex justify-content-center mt-3">
          <Spinner color="primary" />
        </div>
      ) : (
        tableData.length > 0 && (
          <DataTable
            data={tableData}
            columns={tableColumns}
            striped={true}
            center={true}
            pagination
          />
        )
      )}
    </div>
  );
};

export default OpenAIQueryComponent;
