import React, { useState, useEffect, Fragment, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { Nav, NavItem, NavLink, Input, CardHeader, Row, Col,Card, CardBody } from 'reactstrap';
import { H4, Btn, Ribbon } from '../../../AbstractElements';
import { API_URL } from '../../../config';
import axios from 'axios';

const DataTableComponent = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [filters, setFilters] = useState({});
  const [period, setPeriod] = useState('1M');
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(10);

  const defaultVisibleColumns = {
    nr_tichet_cantar: true,
    data_intrare: true,
    furnizor: true,
    cod_deseu: true,
    provenienta: true,
    kg_brut: true,
    kg_tara: true,
    tone_net: true,
    nr_vehicul: true,
    contract: true,
    id: false,
    CodFurnizor: false,
    cod_gestiune: false,
    tip_tranzactie: false,
    generator: false,
    operatie: false,
    status: false
  };

  const [visibleColumns, setVisibleColumns] = useState(defaultVisibleColumns);
  const keyDisplayNames = {
    nr_tichet_cantar: 'Nr. Tichet Cantar',
    data_intrare: 'Data Intrare',
    furnizor: 'Furnizor',
    CodFurnizor: 'Cod Furnizor',
    cod_deseu: 'Cod Deseu',
    nr_vehicul: 'Nr. Vehicul',
    provenienta: 'Proveniență',
    kg_brut: 'Kg Brut',
    kg_tara: 'Kg Tara',
    kg_net: 'Kg Net',
    tone_net: 'Tone Net',
    cod_gestiune: 'Cod Gestiune',
    tip_tranzactie: 'Tip Tranzacție',
    generator: 'Generator',
    operatie: 'Operatie',
    contract: 'Contract',
    status: 'Status',
    // Adaugă toate cheile care trebuie transformate aici
  };
  const fetchData = async (page = 1) => {
    try {
      const response = await axios.get(`${API_URL}users/widgets/get-all-deposits`, {
        params: {
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
          page,
          limit,
          ...filters
        }
      });
      const { tableData, totalRecords } = response.data;
      setTableData(tableData);
      setTotalRecords(totalRecords);

      if (tableData.length > 0) {
        const columns = Object.keys(tableData[0]);
        setTableColumns(columns);
      }
    } catch (error) {
      console.error('Error fetching table data:', error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [filters, startDate, endDate, currentPage]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleAcceptInfo = async () => {
    try {
      const response = await axios.post(`${API_URL}users/widgets/accept-info`, {
        selectedRows
      });
      if (response.status === 200) {
        alert('Information accepted successfully');
        setToggleCleared(!toggleCleared);
      }
    } catch (error) {
      console.error('Error accepting information:', error);
    }
  };


  const handlePeriodChange = (item) => {
    let newStartDate = new Date();
    let newEndDate = new Date();

    switch (item) {
      case '1D':
        newStartDate.setDate(newEndDate.getDate());
        break;
      case '1W':
        newStartDate.setDate(newEndDate.getDate() - 7);
        break;
      case '1M':
        newStartDate = new Date(newEndDate.getFullYear(), newEndDate.getMonth(), 1);
        break;
      case '1T':
        const currentMonth = newEndDate.getMonth();
        const startMonth = currentMonth - (currentMonth % 3);
        newStartDate = new Date(newEndDate.getFullYear(), startMonth, 1);
        break;
      case '1Y':
        newStartDate = new Date(newEndDate.getFullYear(), 0, 1);
        break;
      default:
        break;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setPeriod(item);
    setCurrentPage(1);
  };

  const handleColumnVisibilityChange = (column) => {
    setVisibleColumns({
      ...visibleColumns,
      [column]: !visibleColumns[column],
    });
  };

  const periods = ['1D', '1W', '1M', '1T', '1Y'];

  const filteredColumns = tableColumns.filter(column => visibleColumns[column]);

  const ExpandedRow = ({ data }) => (
    <Row  style={{ padding: '30px' }}>
      {Object.entries(data).map(([key, value]) => (
      <Col md="2" key={key} className="m-1 p-1 " style={{  fontSize: '12px' }}>
        {keyDisplayNames[key] || key}:<span className='text-muted small-font-size' > {value}</span>
      </Col>
    ))}
    </Row>
  );

  return (
    <Fragment> 
      <Card>
      <CardHeader>
        <div className="header-top">
        <div class="d-flex">
            <Input
              type="date"
              className='form-control  form-control-sm'
              value={startDate.toISOString().split('T')[0]}
              onChange={(e) => setStartDate(new Date(e.target.value))}
            />
            <Input
              type="date"
              className='form-control  form-control-sm'
              value={endDate.toISOString().split('T')[0]}
              onChange={(e) => setEndDate(new Date(e.target.value))}
            />
          </div>
          <div className='card-header-right-icon'>
            <Nav tabs className='custom-tab'>
              {periods.map((item, i) => (
                <NavItem key={i}>
                  <NavLink tag='button' onClick={() => handlePeriodChange(item)} className={`nav-link ${period === item && 'active'}`}>
                    {item}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </div>
         
        </div>
      </CardHeader>
      <CardBody>
      
      {selectedRows.length > 0 && (
        <div className={`d-flex align-items-center justify-content-between p-2`}>
          <H4 attrH4={{ className: 'text-muted m-0' }}>Accepta date!</H4>
          <Btn attrBtn={{ color: 'primary', onClick: handleAcceptInfo }}>Accepta</Btn>
        </div>
      )}
      <DataTable
        data={tableData}
        columns={filteredColumns.map(column => ({
          name: column,
          selector: row => row[column],
          sortable: true,
          filterable: true,
          cell: row => row[column],
        }))}
        striped={true}
        center={true}
        pagination
        paginationServer
        paginationTotalRows={totalRecords}
        onChangePage={page => setCurrentPage(page)}
        onChangeRowsPerPage={newLimit => {
          setCurrentPage(1);
          setLimit(newLimit);
        }}
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        expandableRows
        expandableRowsComponent={({ data }) => <ExpandedRow data={data} />}
      />
      </CardBody>
      </Card>
    </Fragment>
  );
};

export default DataTableComponent;
