import { Row, Col, Card, CardBody,CardHeader, Nav, NavItem, NavLink,FormGroup, Label, Input, Button} from 'reactstrap';


import React, { useState, useEffect } from 'react';
import { H5, UL, LI } from '../../../AbstractElements';
import { Earning, Expense, OverallBalanceTitle } from '../../../Constant';
import LightCardBox from './LightCardBox';
import ReactApexChart from 'react-apexcharts';
import { CurrencyChartData } from '../../../Data/DefaultDashboard/Chart';
import { LightCardData } from '../../../Data/DefaultDashboard';
import axios from 'axios';
import DailyDataChart from '../../Common/CommonWidgets/DailyDataChart';
import { API_URL } from '../../../config';
import DropdownCommon from '../../Common/Dropdown3';
import ConfigDB from "../../../Config/ThemeConfig";
const primary = ConfigDB.data.color.primary_color;

const Dashboard = () => {
  const [data, setData] = useState({
    categories: [],
    totalToneNet: [],
    totalRecords: 0,
    totalKgNet: 0
  });
  const [filter, setFilter] = useState('this_month'); // Filtru implicit
  const [period, setPeriod] = useState('1M');
  
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  

  const fetchData = async (startDate, endDate) => {
    try {
      const response = await axios.get(`${API_URL}users/widgets/waste-flow-data`, {
        params: {
          rol: localStorage.getItem('rol'),
          nume: localStorage.getItem('name'),
          filter,
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
        }
      });
      const apiData = response.data;

      const categories = apiData.map(item => item.cod_deseu);
      const totalToneNet = apiData.map(item => Math.round(item.total_tone_net)); // Rotunjim valorile
      setData({ categories, totalToneNet });
    } catch (error) {
      console.error("Eroare la preluarea datelor:", error);
    }
  };

  useEffect(() => {
    fetchData(startDate, endDate);
  }, [filter]);

  const handlePeriodChange = (item) => {
    let newStartDate = new Date();
    let newEndDate = new Date();

    switch (item) {
      case '1D':
        newStartDate.setDate(newEndDate.getDate());
        break;
      case '1W':
        newStartDate.setDate(newEndDate.getDate() - 7);
        break;
      case '1M':
        newStartDate = new Date(newEndDate.getFullYear(), newEndDate.getMonth(), 1);
        break;
      case '1T':
        const currentMonth = newEndDate.getMonth();
        const startMonth = currentMonth - (currentMonth % 3);
        newStartDate = new Date(newEndDate.getFullYear(), startMonth, 1);
        break;
      case '1Y':
        newStartDate = new Date(newEndDate.getFullYear(), 0, 1);
        break;
      default:
        break;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setPeriod(item);
    fetchData (newStartDate, newEndDate);
  };

 

  

const chartData = {
    series: [
        {
            name: 'Tone',
            data: data.totalToneNet,
        },
    ],
    
    options: {
        chart: {
          type: 'bar',
          height: 300,
          stacked: true,
          toolbar: {
            show: false,
          
        },
        
        dropShadow: {
            enabled: true,
            top: 8,
            left: 0,
            blur: 10,
            color: "#7064F5",
            opacity: 0.1,
          },
    },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "40%",
                borderRadius: 0,
              
            },
        },
        dataLabels: {
            enabled: false,
          },
        stroke: {
            width: 2,
            dashArray: 0,
            lineCap: "butt",
            colors: "#fff",
          },
          fill: {
            opacity: 1,
          },
          legend: {
            show: false,
          },
          states: {
            hover: {
              filter: {
                type: "darken",
                value: 1,
              },
            },
          },
          colors: ["#7064F5", "#8D83FF"],
          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "vertical",
              gradientToColors: ["#7064F5", "#8D83FF"],
              opacityFrom: 0.98,
              opacityTo: 0.85,
              stops: [0, 100],
            },
          },
          responsive: [
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 200,
                },
              },
            },
          ],
        xaxis: {
            categories: data.categories,
            labels: {
                show: true,
                style: {
                    fontFamily: 'Rubik, sans-serif',
                    fontSize: '9px',
                    width: '40px',

                    whiteSpace: 'normal',
                },
                trim:true,
                show: true,
                rotate: -45, // Rotate the labels by -45 degrees
                formatter: function (value) {
                    // Limitează lățimea etichetelor
                    return value;
                },
                tooltip: {
                  enabled: true,
                }
            },
            axisBorder: {
                offsetX: 0,
                offsetY: 0,
                show: false,
            },
            axisTicks: {
                offsetX: 0,
                offsetY: 0,
                show: false,
            },
        },
        yaxis: {
            min: 0,
            show: true,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        tooltip: {
            shared: false,
            x: {
                show: true,
            },
            y: {
                show: true,
            },
            z: {
                show: true,
            },
        },
    },
};
const periods = ['1D', '1W', '1M', '1T', '1Y'];

  return (
  
<Col xxl='6' lg='12' className='box-col-12 d-flex'>
<Card className="flex-fill">
  <CardHeader className='card-no-border'>
  <div class="header-top">
    <H5>Flux deseuri</H5>
    <div className='card-header-right-icon'>
    <Nav tabs className='custom-tab'>
              {periods.map((item, i) => (
                <NavItem key={i}>
                  <NavLink tag='button' onClick={() => handlePeriodChange(item)} className={`nav-link ${period === item && 'active'}`}>
                    {item}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
     </div>
          <div className='d-flex'>
            <Input
              type="date"
              className='form-control form-control-sm'
              value={startDate.toISOString().split('T')[0]}
              onChange={(e) => setStartDate(new Date(e.target.value))}
            />
            <Input
              type="date"
              className='form-control form-control-sm'
              value={endDate.toISOString().split('T')[0]}
              onChange={(e) => setEndDate(new Date(e.target.value))}
            />
          </div>
                  </div>
             
  </CardHeader>
  <CardBody className='pt-0'>
    <Row className='m-0 overall-card'>
    <Col xl='12'>
    <ReactApexChart type='bar' height={370} options={chartData.options} series={chartData.series} />
    </Col>
       </Row>
  </CardBody>
</Card>
</Col>
  );
};

export default Dashboard;
