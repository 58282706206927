import axios from 'axios';
import { API_URL } from '../config';

const token = localStorage.getItem('token');

const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
};

export const getUsers = async () => {
    return axios.get(`${API_URL}users`, { headers });
};

export const createUser = async (data) => {
    return axios.post(`${API_URL}users/create`, data, { headers });
};

export const updateUser = async (id, data) => {
    return axios.put(`${API_URL}users/update/${id}`, data, { headers });
};

export const deleteUser = async (id) => {
    return axios.delete(`${API_URL}users/delete/${id}`, { headers });
};
