import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Deutsch, English, Español, Français, Português, 简体中文, Romanian } from '../../../Constant';

const Language = () => {
  const [langdropdown, setLangdropdown] = useState(false);
  const [selected, setSelected] = useState('ro');
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelected(lng);
    setLangdropdown(false); // Închide dropdown-ul după schimbarea limbii
  };

  const LanguageSelection = (language) => {
    setLangdropdown(!langdropdown); // Toggle dropdown
  };

  return (
    <li className='language-nav'>
      <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`}>
        <div className='current_lang'>
          <div className='lang' onClick={LanguageSelection}>
            <i className={`flag-icon flag-icon-${selected === 'ro' ? 'ro' : selected === 'en' ? 'us' : selected}`}></i>
            <span className='lang-txt'>{selected}</span>
          </div>
        </div>
        <div className={`more_lang ${langdropdown ? 'active' : ''}`}>
          <div className='lang' onClick={() => changeLanguage('ro')}>
            <i className='flag-icon flag-icon-ro'></i>
            <span className='lang-txt'>{Romanian}</span>
          </div>
          <div className='lang' onClick={() => changeLanguage('en')}>
            <i className='flag-icon flag-icon-en'></i>
            <span className='lang-txt'>
              {English}
              <span> {'(US)'}</span>
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Language;
