import { Row, Col, Card, CardBody, CardHeader, Button, Nav, NavItem, NavLink,Input } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { H5 } from '../../../AbstractElements';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import { API_URL } from '../../../config';
import DropdownCommon from '../../Common/Dropdown3';
import ConfigDB from "../../../Config/ThemeConfig";
const primary = ConfigDB.data.color.primary_color;

const WastePerCapitaChart = () => {
  const [data, setData] = useState({
    categories: [],
    perCapitaWaste: []
  });
  const [filter, setFilter] = useState('1M'); // Filtru implicit
  
  const [period, setPeriod] = useState('1M');
  
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  
  const fetchData = async (filter) => {
    try {
      const response = await axios.get(`${API_URL}users/widgets/waste-per-capita`, {
        params: {
          rol: localStorage.getItem('rol'),
          nume: localStorage.getItem('name'),
          period: filter,
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
        }
      });
      const apiData = response.data;

      const categories = apiData.map(item => item.sector);
      const perCapitaWaste = apiData.map(item => Math.round(item.perCapitaWaste));
      setData({ categories, perCapitaWaste });
    } catch (error) {
      console.error("Eroare la preluarea datelor:", error);
    }
  };

  useEffect(() => {
    fetchData(filter);
  }, [filter]);



  const handlePeriodChange = (item) => {
    let newStartDate = new Date();
    let newEndDate = new Date();

    switch (item) {
      case '1D':
        newStartDate.setDate(newEndDate.getDate());
        break;
      case '1W':
        newStartDate.setDate(newEndDate.getDate() - 7);
        break;
      case '1M':
        newStartDate = new Date(newEndDate.getFullYear(), newEndDate.getMonth(), 1);
        break;
      case '1T':
        const currentMonth = newEndDate.getMonth();
        const startMonth = currentMonth - (currentMonth % 3);
        newStartDate = new Date(newEndDate.getFullYear(), startMonth, 1);
        break;
      case '1Y':
        newStartDate = new Date(newEndDate.getFullYear(), 0, 1);
        break;
      default:
        break;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setPeriod(item);
    fetchData(newStartDate, newEndDate);
  };



  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };
  const periods = ['1D', '1W', '1M', '1T', '1Y'];

  const filters = [
    { value: '1D', label: '1D' },
    { value: '1W', label: '1W' },
    { value: '1M', label: '1M' },
    { value: '1T', label: '1T' },
    { value: '1Y', label: '1Y' },
  ];

  const chartData = {
    series: [
      {
        name: 'Deșeuri per capita',
        data: data.perCapitaWaste,
      },
    ],
    
    options: {
      chart: {
        type: 'bar',
        height: 200,
        stacked: true,
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          top: 8,
          left: 0,
          blur: 10,
          color: "#7064F5",
          opacity: 0.1,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "30%",
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        dashArray: 0,
        lineCap: "butt",
        colors: "#fff",
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
      },
      colors: [primary],
      xaxis: {
        categories: data.categories,
        labels: {
          show: true,
          style: {
            fontFamily: 'Rubik, sans-serif',
            fontSize: '10px',
            maxWidth: 30,
            whiteSpace: 'normal',
          },
          formatter: function (value) {
            return value;
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        min: 0,
        show: true,
        title: {
          text: 'Deșeuri per capita (kg/persoană)',
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        shared: false,
        x: {
          show: true,
        },
        y: {
          show: true,
        },
        z: {
          show: true,
        },
      },
    },
  };

  return (
    <Col xxl='6' lg='12' className='box-col-12  d-flex'>
      <Card className="flex-fill">
        <CardHeader className='card-no-border'>
          <div className="header-top">
            <H5>Per capita</H5>
            <div className='card-header-right-icon'>
            <Nav tabs className='custom-tab'>
              {periods.map((item, i) => (
                <NavItem key={i}>
                  <NavLink tag='button' onClick={() => handlePeriodChange(item)} className={`nav-link ${period === item && 'active'}`}>
                    {item}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            </div>
            <div className='d-flex'>
            <Input
              type="date"
              className='form-control form-control-sm'
              value={startDate.toISOString().split('T')[0]}
              onChange={(e) => setStartDate(new Date(e.target.value))}
            />
            <Input
              type="date"
              className='form-control form-control-sm'
              value={endDate.toISOString().split('T')[0]}
              onChange={(e) => setEndDate(new Date(e.target.value))}
            />
          </div>
                
          </div>
        </CardHeader>
        <CardBody className='pt-0'>
          <Row className='m-0 overall-card'>
            <Col xl='12'>
              <ReactApexChart type='bar' height={300} options={chartData.options} series={chartData.series} />
            </Col>
          </Row>
        </CardBody>
     
        </Card>
    </Col>
  );
};

export default WastePerCapitaChart;