import React, { useState, useEffect } from 'react';
import { H6, Image, P } from '../../../AbstractElements';
import { Input, Button, Row, Col, FormGroup, Label, Form, Card, CardBody, CardFooter, CardHeader, CardTitle} from 'reactstrap';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { X, PlusCircle } from 'react-feather';
import { API_URL } from '../../../config';

const DynamicFilterComponent = () => {
  const [filters, setFilters] = useState([]);
  const [field, setField] = useState('');
  const [operator, setOperator] = useState('');
  const [value, setValue] = useState('');
  const [condition, setCondition] = useState('AND');
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);

  const fields = [
    { name: 'nr_tichet_cantar', type: 'number' },
    { name: 'data_intrare', type: 'datetime' },
    { name: 'furnizor', type: 'text' },
    { name: 'CodFurnizor', type: 'text' },
    { name: 'cod_deseu', type: 'text' },
    { name: 'nr_vehicul', type: 'text' },
    { name: 'provenienta', type: 'text' },
    { name: 'kg_brut', type: 'number' },
    { name: 'kg_tara', type: 'number' },
    { name: 'kg_net', type: 'number' },
    { name: 'tone_net', type: 'number' },
    { name: 'cod_gestiune', type: 'text' },
    { name: 'tip_tranzactie', type: 'text' },
    { name: 'generator', type: 'text' },
    { name: 'operatie', type: 'text' },
    { name: 'contract', type: 'text' },
    { name: 'status', type: 'number' },
  ];

  const operators = {
    text: ['=', '!=', 'LIKE', 'NOT LIKE'],
    number: ['=', '!=', '>', '<', '>=', '<='],
    datetime: ['=', '!=', '>', '<', '>=', '<='],
  };

  useEffect(() => {
    if (['furnizor', 'cod_deseu', 'nr_vehicul', 'provenienta', 'cod_gestiune', 'generator', 'operatie', 'contract'].includes(field)) {
      fetchDropdownOptions(field);
    } else {
      setDropdownOptions([]);
    }
  }, [field]);

  const fetchDropdownOptions = async (selectedField) => {
    try {
      const response = await axios.get(`${API_URL}data/widgets/get-field-values`, {
        params: { field: selectedField }
      });
      setDropdownOptions(response.data);
    } catch (error) {
      console.error('Error fetching dropdown options:', error);
    }
  };

  const addFilter = () => {
    setFilters([...filters, { field, operator, value, condition }]);
    setField('');
    setOperator('');
    setValue('');
    setCondition('AND');
  };

  const removeFilter = (index) => {
    setFilters(filters.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${API_URL}data/widgets/generate-query`, { filters });
      const { columns, data } = response.data;

      setTableColumns(columns.map(column => ({
        name: column,
        selector: row => row[column],
        sortable: true,
      })));
      setTableData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (


    <div>
                <Card className=''>
                        <CardHeader >
                                    <Form>
                                        <Row form>
                                        <Col md={2}>
                                            <FormGroup>
                                            <Label for="fieldSelect">Element</Label>
                                            <Input type="select" className='form-control-sm' id="fieldSelect" value={field} onChange={(e) => setField(e.target.value)}>
                                                <option value="">Alege un element</option>
                                                {fields.map(f => (
                                                <option key={f.name} value={f.name}>{f.name}</option>
                                                ))}
                                            </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                            <Label for="operatorSelect">Operator</Label>
                                            <Input type="select" id="operatorSelect" value={operator} onChange={(e) => setOperator(e.target.value)}>
                                                <option value="">Alege Operator</option>
                                                {field && operators[fields.find(f => f.name === field).type].map(op => (
                                                <option key={op} value={op}>{op}</option>
                                                ))}
                                            </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                            <Label for="valueInput">Valoare</Label>
                                            {dropdownOptions.length > 0 ? (
                                                <Input
                                                type="select"
                                                id="valueInput"
                                                value={value}
                                                onChange={(e) => setValue(e.target.value)}
                                                >
                                                <option value="">Selectează Valoare</option>
                                                {dropdownOptions.map(option => (
                                                    <option key={option} value={option}>{option}</option>
                                                ))}
                                                </Input>
                                            ) : (
                                                <Input
                                                type={fields.find(f => f.name === field)?.type === 'datetime' ? 'date' : 'text'}
                                                id="valueInput"
                                                value={value}
                                                onChange={(e) => setValue(e.target.value)}
                                                />
                                            )}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                            <Label for="conditionSelect">Condiție</Label>
                                            <Input type="select" id="conditionSelect" value={condition} onChange={(e) => setCondition(e.target.value)}>
                                                <option value="AND">ȘI</option>
                                                <option value="OR">SAU</option>
                                            </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={1} className="d-flex align-items-center">
                                        <PlusCircle className='plus-circle'  onClick={addFilter} />
                                            
                                        </Col>
                                        <Col>
                                        
                                              <div>  <Button color="primary" onClick={handleSubmit} className="mt-3">Trimite</Button></div>
                                        </Col>
                                        </Row>
                                    </Form>
                        </CardHeader>
                        <CardBody>
                        
                        <div className="mt-3">
                        <Row className='g-sm-4 g-3'>
                            {filters.map((filter, index) => (
                             <Col xxl='auto' md='auto' key={index}>
                            <div className='prooduct-details-box'>
                            <div className='media'>
                               <div className='media-body ms-3'>
                                
                                    <div className='avaiabilty'>
                                  <div className='text-success'> {filter.field} {filter.operator} {filter.value} ({filter.condition})</div>
                                </div>
                                
                                <X className='close' onClick={() => removeFilter(index)} />
                              </div>
                            </div>
                          </div>
                          </Col>
                            ))}
                            </Row>
                        </div>

                        
                        
                            <P>
                            {tableData.length > 0 && (
                                <DataTable
                                data={tableData}
                                columns={tableColumns}
                                striped={true}
                                center={true}
                                pagination
                                />
                            )}
                            </P>
                        </CardBody>
                    </Card>


     
     
     
     
    </div>
  );
};

export default DynamicFilterComponent;
