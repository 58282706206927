import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import axios from 'axios';
import Widgets1 from '../../Common/CommonWidgets/Widgets1';
import Widgets2 from '../../Common/CommonWidgets/Widgets2';
import Widgets3 from '../../Common/CommonWidgets/Widgets3';
import { API_URL } from '../../../config';


const WidgetsWrapper = () => {
  const [widgetsData, setWidgetsData] = useState({
    totalKgNet: 0,
    totalRecords: 0,
    totalSuppliers: 0,
    chartData2: {
      series: [],
      options: {}
    },
    chartData3: {
      series: [],
      options: {}
    },
    chartDataKgNet: {
      series: [],
      options: {}
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}users/widgets/data`, {
          params: {
            rol: localStorage.getItem('rol'),
            nume: localStorage.getItem('nume')
          }
        });
        setWidgetsData(response.data);
      } catch (error) {
        console.error('Error fetching widgets data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Col xxl='auto' xl='3' sm='6' className='box-col-6 d-flex justify-content-center align-items-center'>
        <Row>
        <Col xl='12'>
          {widgetsData.chartDataKgNet.options.chart && (
          <Widgets1 data={{ title: 'Tone Net', total: widgetsData.totalKgNet, color: 'secondary',   chart: widgetsData.chartDataKgNet }} />
        )}
          </Col>
        </Row>
      </Col>
      <Col xxl='auto' xl='3' sm='6' className='box-col-6 d-flex justify-content-center align-items-center'>
        <Row>
          <Col xl='12'>
          {widgetsData.chartData2.options.chart && (
          <Widgets2 data={{ title: 'Inregistrari', total: widgetsData.totalRecords, chart: widgetsData.chartData2 }} />
        )}
          </Col>
        </Row>
      </Col>
      <Col xxl='auto' xl='12' sm='6' className='box-col-6 d-flex justify-content-center align-items-center'>
        <Row>
          <Col xxl='12' xl='6' className='box-col-12'>
          {widgetsData.chartData3.options.chart && (
          <Widgets3 data={{ title: 'Furnizori', total: widgetsData.totalSuppliers, chart: widgetsData.chartData3 }} />
        )}
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default WidgetsWrapper;
